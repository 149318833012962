import React from "react"

import Layout from "../components/layout"

const ContactPage = () => (
    <Layout>
        <section id="welcomeSection" className="section-description">
            <h1>Contact</h1>
            <p>Need help on a project or want to talk? Send me a message and I will get back to you as soon as I can.</p>
        </section>
        <div>
            <form className="form-style-7" name="contact" method="POST" data-netlify="true">
                <ul>
                    <li>
                        <label for="name">Name</label>
                        <input type="text" name="name" maxlength="100" />
                        <span>Enter your full name here</span>
                    </li>
                    <li>
                        <label for="email">Email</label>
                        <input type="email" name="email" maxlength="100" />
                        <span>Enter a valid email address</span>
                    </li>
                    <li>
                        <label for="message">Message</label>
                        <textarea name="message" rows="6"></textarea>
                        <span>Say something about yourself</span>
                    </li>
                    <li>
                        <button className="btn btn-blue" type="submit">Contact Me</button>
                    </li>
                </ul>
            </form>
        </div>
    </Layout>
)

export default ContactPage